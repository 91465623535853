import axios from 'axios';
import cn from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import Spinner from '../Spinner/Spinner';

const ProjectUrlModal = ({
  toggleProjectUrlModal,
  showProjectUrlModal,
  projectUrl,
  projectData,
}) => {
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [copied, setCopied] = useState(false);
  const [success, setSuccess] = useState(false);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState(false);

  const retryLoading = useRef(0);

  const thumbnail = `https://storage.ruttl.com/thumbnails/${projectData.projectID}-${projectData.pageID}.jpg?alt=media`;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const imgLoadHandler = (e) => {
    e.target.style.opacity = '1';
  };

  const imgErrorHandler = (e) => {
    const { target } = e;
    const { src } = target;

    let timeout = null;

    e.target.style.opacity = '0';
    retryLoading.current += 1;
    if (retryLoading.current < 10) {
      timeout = setTimeout(() => {
        target.src = '';
        target.src = src;
      }, 1000);
    }
    return () => timeout && clearTimeout(timeout);
  };

  const sendEmail = () => {
    if (email === '') {
      setError('Please enter an email address');
      return;
    }
    if (!isEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    (async () => {
      try {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('url', projectUrl);
        formData.append('action', 'invite');
        await axios.post(
          'https://app.ruttl.com/apphighmem/anonymous-project',
          formData,
        );
        setSendingEmail(false);
        setEmail('');
        setReceiveEmail(false);
        setSuccess(true);
      } catch (err) {
        setSendingEmail(false);
      }
    })();
  };

  useEffect(() => {
    setThumbnailLoaded(false);
    setTimeout(() => {
      setThumbnailLoaded(true);
    }, 6000);
  }, []);

  return (
    <div
      id="waitlist-modal"
      className={cn(['contact-us-modal', { show: showProjectUrlModal }])}>
      <div className="contact-us-container project-url-container">
        <button
          type="button"
          aria-label="close"
          className="button-unstyled close-button project-url-modal"
          onClick={() => toggleProjectUrlModal()}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M368 368L144 144M368 144L144 368"
            />
          </svg>
        </button>

        <div className="content">
          <div className="content-left">
            <div className="thumbnail">
              {thumbnailLoaded ? (
                <img
                  loading="lazy"
                  src={thumbnail}
                  alt="thumbnail"
                  onLoad={imgLoadHandler}
                  onError={imgErrorHandler}
                  style={{ opacity: 0 }}
                />
              ) : (
                <>
                  <Spinner />
                  <p className="loading-text">Loading thumbnail...</p>
                </>
              )}
            </div>
          </div>
          <div className="content-right">
            <h2>Your site is ready to roll!</h2>
            <p className="sub-text">
              Here is the unique link which you can use to share with your
              stakeholder to gather feedback
            </p>

            <div className="url-container">
              <div className="url">
                <div className="link">
                  <span>{projectUrl}</span>
                </div>
                <button
                  type="button"
                  className="button copy-btn"
                  onClick={() => copyToClipboard(projectUrl)}>
                  {copied ? 'Copied' : 'Copy'}
                </button>
              </div>
              <button
                type="button"
                className="button open-btn"
                onClick={() => window.open(projectUrl, '_blank')}>
                Open Page
              </button>
            </div>
            <button
              className="button-unstyled receive"
              onClick={() => {
                setError(false);
                setSuccess(false);
                setReceiveEmail(true);
              }}
              type="button">
              Receive link in email
            </button>
            {receiveEmail && (
              <div className="email-container">
                <div className={`email-address  ${focus ? 'focus' : ''}`}>
                  <div className="email">
                    <input
                      placeholder="Enter email"
                      value={email}
                      type="email"
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          sendEmail();
                        }
                      }}
                      onChange={(e) => {
                        setError(false);
                        setEmail(e.target.value);
                      }}></input>
                  </div>
                  <button
                    type="button"
                    className="button send-email"
                    disabled={sendingEmail}
                    onClick={() => sendEmail()}>
                    {sendingEmail ? 'Sending...' : 'Submit'}
                  </button>
                </div>
              </div>
            )}
            {success && (
              <div className="success">
                <p> Email sent successfully!</p>
              </div>
            )}
            {error && (
              <div className="error">
                <p>{error}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
ProjectUrlModal.propTypes = {
  toggleProjectUrlModal: PropTypes.func,
  showProjectUrlModal: PropTypes.bool,
  projectUrl: PropTypes.string,
  projectData: PropTypes.object,
};

export default ProjectUrlModal;
